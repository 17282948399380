@import '../../../styles/colors';

footer {
    color: $lightGray;
    padding: 40px 0;
    font-size: 14px;
}

footer .nopadding {
    padding-left: 0 !important;
 }

.footer-content {
    display: flex;
    align-items: center;
}

.footer-logo img {
    max-width: 100px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.footer-links a {
    color: $lightGray;
    text-decoration: none;
}

.footer-links a:hover {
    color: $indigo;
}

.footer-copyright {
    text-align: center;
    flex-basis: 100%;
    margin-top: 10px;
}