@import '../../../styles/colors';
$horizontalPadding: 12vw;
$headerMargin: 8vw;
$screen-md-min: "768px";

@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

body {
  background-color: $background;
  padding-left: $horizontalPadding;
  padding-right: $horizontalPadding;
}

h1 {
  font-size: 64px;
  line-height: 1;
  font-weight: 600;
}

h1 span {
  color: $indigo;
}

.home {
  margin-top: $headerMargin;
}

.home .description {
  margin-top: 24px;
  margin-bottom: 40px;
}

.home .buttons img {
  margin-right: 16px;
  margin-bottom: 16px;
  height: 40px;
}

.home .buttons {
  margin-bottom: 24px;
}

.home .hero img {
  width: 100%;

  @include md {
    float: right;
    width: 80%;
  }
}

.header {
  margin-top: 40px;
  margin-bottom: 40px;
}

.header a {
  cursor: pointer;
}

.logo {
  height: 24px;
}