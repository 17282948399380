@import 'colors';

.page h1 {
    font-size: 40px;
}

.page h2 {
    margin-bottom: 24px;
    margin-top: 40px;
}

.modified {
    color: $night;
    opacity: 0.6;
    font-size: 12px;
    margin-bottom: 32px;
}