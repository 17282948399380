@import 'colors';

@mixin selection {
    ::-moz-selection { @content; }
    ::selection { @content; }
}

// use it
@include selection {
    color: white;
    background: $indigo;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: $night !important;
  background-color: $background;
}